import { styled } from '@abyss/web/tools/styled';
/* eslint-disable no-nested-ternary */
import { Carousel, Slide } from '@abyss/web/ui/Carousel';
import { Text } from '@abyss/web/ui/Text';
import find from 'lodash/find';
import { useContext } from 'react';
import React from 'react';

import { CountySearchContext } from '../../context/CountySearchContext';
import { useLagoon } from '../../hooks/useLagoon';
import { SnackCardProvider } from '../../hooks/useSnackCardProviderSearch/useSnackCardProviderSearch';
import { ResponseHeaders } from '../../models/ResponseHeaders';
import { getCurrentMember } from '../../utils/user.utils';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { OutsideCountyAlert } from '../DataCard/OutsideCountyAlert';
import { getGeoLocationFromStorage } from '../PSXHeader/SearchBar/utils';
import { CardInfo } from '../SnackCard/CardInfo';
import { SnackCard } from '../SnackCard/SnackCard';
import {
  carouselStyle,
  slideContainerStyle,
  slideStyleSnack,
} from '../SnackCard/SnackCard.styles';
import { getFeatureFlag } from '../Utils';
import { makeAllSlidesAriaHidden } from '../Utils/a11yUtils/a11yUtils';
import { isMnrMember } from '../Utils/memberUtils/memberUtils';
import { useCheckTerminationDate } from '../Utils/terminateDateIndicatorUtils';
import { isOutOfCounty } from './utils';

export const PreferredBadgeWrapper = styled('div', {
  '@screen < $sm': {
    paddingBottom: '4px',
  },
});

type Props = {
  coverageType?: string;
  snackCardProviders: SnackCardProvider[];
  isLoading: boolean;
  isFixes: boolean;
  locationForAnalytics?: string;
  rulesPackageKey?: string;
  headers: ResponseHeaders;
};

export const SnackCardCarousel = ({
  coverageType,
  snackCardProviders = [],
  isLoading,
  isFixes,
  locationForAnalytics,
  rulesPackageKey,
  headers,
}: Props) => {
  const featureFlags = useLagoon('feature-flags')();

  const getLinkPositionIndex = (linkPosition?: number) => {
    if (linkPosition !== undefined) {
      return linkPosition + 1;
    }
    return linkPosition;
  };
  const countySearchAlertFlag: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_COUNTY_SEARCH_ALERT
  );
  const { isCountySearch } = useContext(CountySearchContext);
  const { name, stateCode } = getGeoLocationFromStorage();

  const noProvidersFoundData = useLagoon('ui-messaging')();
  const noProvidersFoundDataContent = find(noProvidersFoundData, {
    key: ConstantsLagoon.NO_PROVIDERS_FOUND,
  });
  const linkLocationName = `body:${locationForAnalytics}`;
  const { lineOfBusiness } = getCurrentMember();
  const isMNR = isMnrMember(lineOfBusiness);

  const getSlides = () => {
    if (isLoading) {
      return [1, 2, 3, 4, 5].map((item) => (
        <SnackCard isFixes={isFixes} isLoading={isLoading} key={item} />
      ));
    }

    if (snackCardProviders.length) {
      return snackCardProviders.map((snackCardProvider, index) => {
        const isOutOfCnty = isOutOfCounty(
          isCountySearch,
          name,
          stateCode,
          snackCardProvider
        );
        const isNetworkAlertIndicator = snackCardProviders.some(
          (snackCardProvider) =>
            useCheckTerminationDate(snackCardProvider?.networkEndDate)
        );
        return (
          <div key={`snack-card-carousel-${snackCardProvider.providerId}`}>
            <CardInfo
              cardType="snack-card-carousel"
              headers={headers}
              indexForAnalytics={getLinkPositionIndex(index)}
              landingPage
              locationForAnalytics={linkLocationName}
              practitioner={snackCardProvider}
              rulesPackageKey={rulesPackageKey}
            />
            <Slide css={slideStyleSnack}>
              <Slide.Container css={slideContainerStyle}>
                <SnackCard
                  coverageType={coverageType}
                  distance={snackCardProvider.distance}
                  hasPhysicalAddress={!!snackCardProvider.address}
                  headers={headers}
                  imgSrc={snackCardProvider.imageLocation}
                  index={index}
                  isFixes={isFixes}
                  isLoading={isLoading}
                  isMNR={isMNR}
                  isNetworkAlertIndicator={isNetworkAlertIndicator}
                  isTieredProvider={snackCardProvider?.isTieredProvider}
                  isVirtualCareOffered={
                    !!snackCardProvider.virtualCareOffered?.length
                  }
                  key={snackCardProvider.locationId}
                  locationForAnalytics={locationForAnalytics}
                  locationId={snackCardProvider.locationId}
                  networkEndDate={snackCardProvider?.networkEndDate}
                  organizationCode={snackCardProvider.organizationCode}
                  organizationType={snackCardProvider.organizationType}
                  professionalDesignation={
                    snackCardProvider.primaryDegrees?.length
                      ? snackCardProvider.primaryDegrees[0]
                      : ''
                  }
                  providerId={snackCardProvider.providerId}
                  providerName={snackCardProvider.providerName}
                  providerType={snackCardProvider.providerType}
                  rating={
                    snackCardProvider.healthGradeRating
                      ? parseFloat(
                          parseFloat(
                            snackCardProvider.healthGradeRating
                          ).toFixed(1)
                        )
                      : undefined
                  }
                  snackCardProvider={snackCardProvider}
                  speciality={snackCardProvider?.speciality}
                  website={snackCardProvider?.website || ''}
                />
              </Slide.Container>
            </Slide>
            {countySearchAlertFlag && (
              <OutsideCountyAlert
                county={name?.split(',')[0]}
                isLandingPage
                isOutOfCounty={isOutOfCnty}
              />
            )}
          </div>
        );
      });
    }

    return (
      <Text
        color="#6E7072"
        css={{
          'abyss-text-root': {
            fontWeight: 500,
          },
        }}
      >
        {noProvidersFoundDataContent?.message ?? ''}
      </Text>
    );
  };

  makeAllSlidesAriaHidden();
  return (
    <Carousel
      css={carouselStyle}
      data-auto-testid="browse-facilities-carousel"
      data-testid="browse-facilities-carousel"
      minimal
      nextSlideOnClick={() => makeAllSlidesAriaHidden()}
      noLoop
      prevSlideOnClick={() => makeAllSlidesAriaHidden()}
      slideIndexOnClick={() => makeAllSlidesAriaHidden()}
      slides={getSlides()}
      slidesPerView={1}
    />
  );
};
