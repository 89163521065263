/* eslint-disable jsx-a11y/control-has-associated-label */
import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSessionStorage } from 'usehooks-ts';
import { useShallow } from 'zustand/react/shallow';

import { SearchFilterContext } from '../../context/SearchFilterContext';
import { useCoverageType } from '../../hooks/useCoverageType';
import { useLagoon } from '../../hooks/useLagoon';
import { Provider } from '../../models/Provider';
import { ProviderType } from '../../models/ProviderDetails';
import { ResponseHeaders } from '../../models/ResponseHeaders';
import { usePCPStore } from '../../store/usePCPStore';
import { PCPSearchStore } from '../../store/usePCPStore/pcpStore';
import { useTypeaheadStore } from '../../store/useTypeaheadStore';
import { TypeaheadState } from '../../store/useTypeaheadStore/typeaheadStore';
import {
  returnSuppressFlag,
  suppressCostEstimatesForPolicies,
} from '../../utils/featureSuppress';
import { setResultIcon, setZoomLevel } from '../../utils/map.utils';
import { getPrimaryPhone } from '../../utils/phone.utils';
import { getProviderSpecialtyOrOrgTypeCode } from '../../utils/providerDetails.utils';
import {
  getNameAttrs,
  parseProviderName,
} from '../../utils/providerNames.utils';
import { Constants } from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { phoneOnly } from '../ConstantsStyles';
import { CardInfo } from '../SnackCard/CardInfo';
import { TierOne } from '../SnackCard/TierOne';
import { getFeatureFlag } from '../Utils';
import { BestMatchDetails } from './BestMatchDetails';
import { DataCard } from './DataCard';
import { DataContainer } from './DataCard.style';
import { DataCardSkeleton } from './DataCardSkeleton';
import { DataCardWithExternalLink } from './DataCardWithExternalLink';

type Props = {
  practitioner?: Provider;
  css?: any;
  sectionType?: string;
  updatePin?: (practitioner?: Provider) => void;
  isLoading?: boolean;
  fromMobileListView?: boolean;
  handleDirectionClick?: () => void;
  index?: number;
  scrollIntoView?: (a: number) => void;
  locationForAnalytics?: string;
  pageNumber?: number;
  map?: any;
  selectedFilters?: string;
  setRouteEndCoords?(coords: [number | null, number | null]): void;
  usedAt?: string;
  searchTerm?: string;
  navigateToDirections?: boolean;
  setNavigateToDirections?: (a: boolean) => void;
  mobileRouteView?: boolean;
  headers?: ResponseHeaders;
  surveyCount?: number;
  isNetworkAlertIndicator?: boolean;
  isMNR?: boolean;
};

const getLinkPositionIndex = (linkPosition?: number) => {
  if (linkPosition !== undefined) {
    return linkPosition + 1;
  }
  return linkPosition;
};

export const DataCardContainer = ({
  practitioner = {},
  css = { width: '100%' },
  sectionType,
  updatePin,
  isLoading,
  fromMobileListView,
  handleDirectionClick,
  index = 0,
  scrollIntoView,
  locationForAnalytics,
  pageNumber,
  map,
  selectedFilters,
  setRouteEndCoords,
  usedAt = '',
  searchTerm = '',
  navigateToDirections = false,
  setNavigateToDirections,
  mobileRouteView = false,
  headers = { correlationId: '' },
  isNetworkAlertIndicator,
  isMNR,
}: Props) => {
  const cardRef = useRef<HTMLElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const {
    providerName,
    primaryDegrees,
    address,
    phones,
    networkStatus,
    distance,
    providerId = '',
    speciality,
    locationId,
    healthGradeRating,
    organizationType = [''],
    organizationCode,
    providerType,
    isTieredProvider,
    website,
    npi = '',
    docasapPracticeId = '',
    networkEndDate,
  } = practitioner;

  const { sectionType: sectionTypeStore } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      sectionType: state.typeaheadSearchStore.sectionType,
      setTypeaheadSearchStore: state.setTypeaheadSearchStore,
    }))
  );
  const { choosePCP } = usePCPStore(
    useShallow((state: PCPSearchStore) => ({
      choosePCP: state.pcpSearchState.choosePCP,
    }))
  );

  const nameAttrs = getNameAttrs(practitioner);

  const { searchFilters, setSearchFilters } = useContext(SearchFilterContext);

  const [isOpen, setIsOpen] = useState(false);
  const featureFlags = useLagoon('feature-flags')();
  const showBestMatchDetails: boolean = getFeatureFlag(
    featureFlags,
    'SHOW_BEST_MATCH_MODAL'
  );
  const virtualCareIndicatorEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.VIRTUAL_CARE_INDICATOR_ENABLED
  );

  const enableCost: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.COST_ENABLED
  );

  const [mapPinCoords] = useSessionStorage(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_COORDS,
    []
  );

  const [selectedId, setSelectedId] = useSessionStorage<string | null>(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_SELECTED_ID,
    null
  );

  const [highlightId, setHighlightId] = useSessionStorage(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_HIGHLIGHT_ID,
    { providerId: '', from: '' }
  );

  if (
    providerId === highlightId?.providerId &&
    highlightId.from === 'map' &&
    scrollIntoView
  ) {
    scrollIntoView(index);
  }

  const setPinOnMap = () => {
    if (updatePin) {
      updatePin(practitioner);
    }
  };
  const name = parseProviderName(
    providerName || '',
    providerType as ProviderType,
    primaryDegrees?.[0],
    nameAttrs
  );

  const highlightClassName = `${
    providerId === highlightId?.providerId ? 'active' : ''
  }`;

  const sectionTypeDerived = sectionType || sectionTypeStore;
  const phone = phones?.phone;
  const appointment = phones?.appointment;
  const primaryPhone = getPrimaryPhone(phone, appointment);
  const suppressPatientReviews = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PATIENT_REVIEWS
  );
  const coverageType = useCoverageType();

  const suppressCostEstimates = suppressCostEstimatesForPolicies(
    coverageType,
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.COST_ESTIMATES,
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.COST_ESTIMATES_EMPIRE
  );
  const pageNumTxt = pageNumber ? `:page ${pageNumber}` : '';
  const linkLocationName = `body:${
    locationForAnalytics || ''
  } card${pageNumTxt}`;
  const mobileScreen = useMediaQuery(phoneOnly);
  useEffect(() => {
    if (navigateToDirections && selectedId === locationId) {
      handleDirectionClick?.();
    }
  }, [navigateToDirections]);

  useEffect(() => {
    if (!mobileRouteView && navigateToDirections) {
      setNavigateToDirections?.(false);
      setSelectedId(null);
    }
  }, [mobileRouteView]);

  const handleHoverOnCard = async () => {
    if (map?.current) {
      await setZoomLevel(map.current, mapPinCoords);
      setResultIcon(map.current, +providerId, true, setHighlightId, providerId);
      setPinOnMap();
    }
    setHighlightId({ providerId, from: usedAt });
  };

  const providerSpeciality = getProviderSpecialtyOrOrgTypeCode(
    providerType,
    organizationType,
    speciality
  );

  if (isLoading) {
    return (
      <DataCardSkeleton
        fromMobileListView={fromMobileListView}
        mobileScreen={mobileScreen}
      />
    );
  }

  if (
    providerType === ProviderType.ORGANIZATION &&
    website &&
    !address?.line?.[0]
  ) {
    return (
      <React.Fragment>
        <DataContainer>
          <CardInfo
            cardType="data-card-with-link"
            headers={headers}
            indexForAnalytics={getLinkPositionIndex(index)}
            landingPage={false}
            locationForAnalytics={linkLocationName}
            onClosePopup={() => setIsOpen(false)}
            practitioner={practitioner}
            searchTerm={searchTerm}
            selectedFilters={selectedFilters}
          />
          <DataCardWithExternalLink
            cardRef={cardRef}
            css={css}
            facilityName={providerName || ''}
            headers={headers}
            highlightClassName={highlightClassName}
            index={index}
            linkLocationName={linkLocationName}
            networkStatus
            practitioner={practitioner}
            providerId={providerId}
            providerSpeciality={providerSpeciality}
            selectedFilters={selectedFilters}
            setHighlightId={setHighlightId}
            updatePin={handleHoverOnCard}
            website={website}
          />
        </DataContainer>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <DataContainer
        data-testid={`data-card-${sectionTypeDerived}-container`.replace(
          / /g,
          '-'
        )}
      >
        <CardInfo
          cardType="data-card"
          headers={headers}
          indexForAnalytics={getLinkPositionIndex(index)}
          landingPage={false}
          locationForAnalytics={linkLocationName}
          onClosePopup={() => setIsOpen(false)}
          practitioner={practitioner}
          searchTerm={searchTerm}
          selectedFilters={selectedFilters}
        />
        {isTieredProvider && <TierOne isTierOne={isTieredProvider} />}
        <DataCard
          address={address}
          cardContainerCss={css}
          cardRef={cardRef}
          choosePCP={choosePCP}
          coverageTypeCode={coverageType}
          distance={distance}
          docasapPracticeId={docasapPracticeId}
          enableCost={enableCost}
          handleDirectionClick={handleDirectionClick}
          handleHoverOnCard={handleHoverOnCard}
          headers={headers}
          healthGradeRating={healthGradeRating}
          highlightClassName={highlightClassName}
          highlightId={highlightId}
          imgRef={imgRef}
          index={index}
          isMNR={isMNR}
          isNetworkAlertIndicator={isNetworkAlertIndicator}
          isTieredProvider={!!isTieredProvider}
          linkLocationName={linkLocationName}
          locationId={locationId}
          map={map}
          name={name}
          networkEndDate={networkEndDate}
          networkStatus={networkStatus}
          npi={npi}
          organizationCode={organizationCode}
          pageNumber={pageNumber}
          practitioner={practitioner}
          primaryPhone={primaryPhone}
          providerId={providerId}
          providerSpeciality={providerSpeciality}
          providerType={providerType}
          searchFilters={searchFilters}
          searchTerm={searchTerm}
          sectionTypeDerived={sectionTypeDerived}
          selectedFilters={selectedFilters}
          setHighlightId={setHighlightId}
          setIsOpen={setIsOpen}
          setNavigateToDirections={setNavigateToDirections}
          setRouteEndCoords={setRouteEndCoords}
          setSearchFilters={setSearchFilters}
          setSelectedId={setSelectedId}
          showBestMatchDetails={showBestMatchDetails}
          speciality={speciality}
          suppressCostEstimates={suppressCostEstimates}
          suppressPatientReviews={suppressPatientReviews}
          surveyCount={practitioner.surveyCount}
          updatePin={updatePin}
          usedAt={usedAt}
          virtualCareIndicatorEnabled={virtualCareIndicatorEnabled}
        />

        {showBestMatchDetails ? (
          <BestMatchDetails
            isOpen={isOpen}
            provider={practitioner}
            setIsOpen={setIsOpen}
          />
        ) : null}
      </DataContainer>
    </React.Fragment>
  );
};
