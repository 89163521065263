import { useRouter } from '@abyss/web/hooks/useRouter';
import { styled } from '@abyss/web/tools/styled';
import { Flex } from '@abyss/web/ui/Flex';
import { Popover } from '@abyss/web/ui/Popover';
import find from 'lodash/find';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { SearchFilterContext } from '../../context/SearchFilterContext';
import { useLagoon } from '../../hooks/useLagoon';
import { useSnackCardProviderResults } from '../../hooks/useSnackCardProviderSearch/useSnackCardProviderSearch';
import { useChipStore } from '../../store/useChipStore';
import { ChipState } from '../../store/useChipStore/chipStore';
import { usePCPStore } from '../../store/usePCPStore';
import { PCPSearchStore } from '../../store/usePCPStore/pcpStore';
import { useSearchStore } from '../../store/useSearchStore';
import { SearchState } from '../../store/useSearchStore/searchStore';
import { useTypeaheadStore } from '../../store/useTypeaheadStore';
import { TypeaheadState } from '../../store/useTypeaheadStore/typeaheadStore';
import { getSnackCardDisplayValByLength } from '../../utils/snackcard.utils';
import { CardHeader } from '../CardHeader/CardHeader';
import {
  Constants,
  FilterCollectionModel,
  ProviderTypes,
  SHOULD_GET_HG_DATA,
} from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { ConstantsRoutes } from '../ConstantsRoutes';
import {
  cardHeaderStyle,
  cardHeaderWithNavigatorStyle,
} from '../ConstantsStyles';
import { ContentWrapper } from '../ContentWrapper';
import { LinkContainer } from '../LinkContainer/LinkContainer';
import { ContainerForAllSectionsStyled } from '../ProviderDetailTabs/ProviderDetailTabs.styled';
import { SnackCardCarousel, SnackCardContainer } from '../SnackCardContainer';
import { convertObjectToUrlParams } from '../Utils';

export const SuggestedProviderDescription = styled('div', {
  color: '$gray7',
  fontWeight: '$bold',
  fontSize: '$lg',
  lineHeight: '24px',
  paddingBottom: '$md',
  '@screen < $md': {
    fontSize: '$md',
    lineHeight: '20px',
  },
});

export const SuggestedProviders = () => {
  const { t } = useTranslation();
  const { navigate } = useRouter();

  const chipStore = useChipStore(useShallow((state: ChipState) => state));
  const { searchType, setTypeaheadSearchStore } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      searchType: state.typeaheadSearchStore.searchType,
      setTypeaheadSearchStore: state.setTypeaheadSearchStore,
    }))
  );
  const { setPCPSearchState } = usePCPStore(
    useShallow((state: PCPSearchStore) => ({
      setPCPSearchState: state.setPCPSearchState,
    }))
  );
  const { setSearchStore } = useSearchStore(
    useShallow((state: SearchState) => ({
      setSearchStore: state.setSearchStore,
    }))
  );

  const [rulesPackageKey, setRulesPackageKey] = useState('');
  const lagoonData = useLagoon('ui-messaging')();
  const suggestedProvidersContent = find(lagoonData, {
    key: ConstantsLagoon.SUGGESTED_PROVIDERS.SUGGESTED_PROVIDERS_MESSAGE,
  });
  const { setDefaultSearchFilters } = useContext(SearchFilterContext);

  const getResultType = (type: string) => {
    let resultType = { sectionType: '', providerType: '', heading: '' };
    if (type === Constants.SEARCH_TYPES.PROVIDER.toLowerCase()) {
      resultType = {
        sectionType: Constants.SEARCH_TYPES.PROVIDER,
        providerType: ProviderTypes.PRACTITIONER,
        heading: t('SUGGESTED_PROVIDERS_CARD.HEADING_SUGGESTED_PROVIDERS'),
      };
    } else if (type === Constants.SEARCH_TYPES.FACILITY.toLowerCase()) {
      resultType = {
        sectionType: Constants.SEARCH_TYPES.FACILITY,
        providerType: ProviderTypes.ORGANIZATION,
        heading: t('SUGGESTED_PROVIDERS_CARD.HEADING_SUGGESTED_FACILITIES'),
      };
    }
    return resultType;
  };

  const { heading, providerType, sectionType } = getResultType(searchType);

  const shouldGetHGData = SHOULD_GET_HG_DATA;

  const { data, isLoading, headers } = useSnackCardProviderResults({
    providerType,
    pcpIndicator: false,
    shouldGetHGData,
  });
  const { snackCardList, searchRadius } = data;

  const numberOfCards = data?.snackCardList?.length;
  const displayCarousel = getSnackCardDisplayValByLength(numberOfCards);
  useEffect(() => {
    if (data?.rulesPackageKey) {
      setRulesPackageKey(data.rulesPackageKey);
    }
  }, [data?.rulesPackageKey]);

  const handleViewAllOnClick = () => {
    setDefaultSearchFilters([FilterCollectionModel.AUTO_SEARCH_RADIUS], {
      autoSearchRadius: searchRadius,
    });

    setTypeaheadSearchStore({
      search: '',
      sectionType,
      providerType,
      searchType: Constants.SEARCH_TYPES.LOCATION,
    });
    setPCPSearchState({ pcpIndicator: false });
    setSearchStore({ searchRadius });

    const urlParams = convertObjectToUrlParams(chipStore, {
      providerType,
      searchRadius,
      sectionType,
      searchType: Constants.SEARCH_TYPES.LOCATION,
    });
    navigate(
      `${ConstantsRoutes.PROVIDER_SEARCH_RESULTS_MAP_VIEW.path}${urlParams}`
    );
  };

  return (
    <ContainerForAllSectionsStyled>
      <ContentWrapper>
        <SuggestedProviderDescription
          data-auto-testid="suggested-provider-card-description"
          data-testid="suggested-provider-card-description"
        >
          {t('SUGGESTED_PROVIDERS_CARD.DESCRIPTION')}
        </SuggestedProviderDescription>
        <Flex alignItems="flex-end" css={cardHeaderStyle} justify="start">
          <CardHeader
            css={cardHeaderWithNavigatorStyle}
            data-auto-testid="suggested-providers"
            data-testid="suggested-providers"
          >
            {heading}
            <Popover
              content={suggestedProvidersContent?.message ?? ''}
              css={{
                'abyss-popover-trigger-icon': {
                  marginLeft: '4px',
                },
              }}
              title={heading}
            />
          </CardHeader>
          {isLoading ? null : (
            <LinkContainer
              aria-label={`${t('View all')} ${t(
                'SUGGESTED_PROVIDERS_CARD.DESCRIPTION'
              )}`}
              data-auto-testid="suggested-providers-cards-view-all"
              data-testid="suggested-providers-cards-view-all"
              onClick={handleViewAllOnClick}
              text={`${t('View all')}`}
            />
          )}
        </Flex>
        {displayCarousel ? (
          <SnackCardCarousel
            headers={headers}
            isFixes={false}
            isLoading={isLoading}
            rulesPackageKey={rulesPackageKey}
            snackCardProviders={snackCardList?.slice(0, 5)}
          />
        ) : (
          <SnackCardContainer
            headers={headers}
            isLoading={isLoading}
            rulesPackageKey={rulesPackageKey}
            snackCardProviders={snackCardList?.slice(0, 5)}
          />
        )}
      </ContentWrapper>
    </ContainerForAllSectionsStyled>
  );
};
